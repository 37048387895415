import React from 'react'
import tw, { styled } from 'twin.macro'
import Layout from '../../containers/Layout/Layout'
import Seo from '../../components/Seo'
import { Link } from 'gatsby'
import PageCover from '../../components/PageCover'
import BreadCrumbArrow from '../../assets/svgs/breadcrumb-arrow.svg'
import CorrosionCoverImage from '../../assets/images/corrosion-cover.png'
import AssetImage from '../../assets/images/asset-integrity-image.jpg'
import Dots from '../../assets/images/service-dots.png'

const Intro = tw.section`max-w-screen-xl mx-auto px-5 py-20 md:pb-32 sm:px-10 lg:px-14 xl:px-28 lg:pt-24 lg:pb-36 flex items-center justify-between`;
const IntroContent = tw.section`md:w-6/12 lg:w-service-item-content`;

const IntroContentHeading = styled.h2`
  ${tw`font-mulish font-extrabold text-brand-dark-200 mb-8 lg:mb-12 text-7/2 md:text-9/2 leading-tight tracking-brand`}
  margin-bottom: ${props => props.marginBottom};
`
const BreakLine = tw.br`hidden lg:block`


const IntroVisuals = styled.section`
  ${tw`hidden md:flex items-start justify-end md:w-5/12 xl:w-service-item-visual relative`}
`
const IntroImageTag = tw.img``;

const DotsImageTag = styled.img`
  ${tw`absolute`};
  z-index: -1;

  @media only screen and (min-width: 768px) {
    bottom: -50px;
    left: -38px;
  }
`;

const ListText = styled.p`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-snug`};
  margin-top: 50px;
  margin-bottom: 21px;
`
const List = styled.ul`
  ${tw`font-lato text-brand-dark-200 text-xl md:text-2xl leading-snug`};
  list-style: disc;
  margin-left: 20px;
`
const FootNoteBg = tw.div`grid gap-x-3 overflow-hidden grid-cols-4`

const FootNoteImage = styled.img`
  ${tw`w-full h-full object-cover relative`};
`;

const cache = {};

function importAll(r) {
    r.keys().forEach((key) => (cache[key] = r(key)));
}




importAll(require.context("../../assets/images/asset-integrity-footnote/", false, /\.(png|jpe?g|svg)$/));

const images = Object.entries(cache).map(module => module[1].default);


const AssetIntegrityManagement = () => {
  return (
    <Layout>
      <Seo title="Service | Asset Integrity Management"/>
      <PageCover
        imageSrc={CorrosionCoverImage}
        coverTitle="Asset Integrity Management"
      >
        <Link to="/">Home</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/services">Our Services</Link>
        <div>
          <BreadCrumbArrow />
        </div>
        <Link to="/services/asset-integrity-management">Asset Integrity Management</Link>
      </PageCover>
      
      <Intro>
        <IntroContent>
          <IntroContentHeading>
            Asset<BreakLine /> Integrity Management
          </IntroContentHeading>
          <ListText>
            The services include:
          </ListText>
          <List>
            <li>NDT Inspection</li>
            <li>Advance NDT Inspection</li>
            <li>Lifting Equipment Inspection</li>
            <li>Vessel & Tank Inspection</li>
          </List>
        </IntroContent>
        <IntroVisuals>
          <IntroImageTag src={AssetImage} alt="Climbing Man" />
          <DotsImageTag src={Dots} alt="Dots background" />
        </IntroVisuals>
      </Intro>

      <FootNoteBg>
        {images.map(image => (
            <FootNoteImage src={image} />
            ))}
        </FootNoteBg>
    </Layout>
  )
}

export default AssetIntegrityManagement
